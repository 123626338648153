<template>
    <div class="container">
        <div class="search-wrap">
            <Input class="inp" search enter-button placeholder="输入项目编号查询" v-model="projectShortName" @on-search="search"/>
            <Button type="primary" @click="writeBack" :loading="btnloading">手动回写</Button>
        </div>
        <div class="table-wrap">
            <Table :columns="columns1" :data="data1" stripe border :loading="loading" >
                <template slot-scope="{ row, index }" slot="signingOffset">
                    <Input v-model="signingOffset" placeholder="输入天数" v-if="index == editIndex"/>
                    <div v-else>{{row.signingOffset}}</div>
                </template>
                <template slot-scope="{ row, index }" slot="startOffset">
                    <Input v-model="startOffset" placeholder="输入天数" v-if="index == editIndex"/>
                    <div v-else>{{row.startOffset}}</div>
                </template>
                <template slot-scope="{ row, index }" slot="basicOffset">
                    <Input v-model="basicOffset" placeholder="输入天数" v-if="index == editIndex"/>
                    <div v-else>{{row.basicOffset}}</div>
                </template>
                <template slot-scope="{ row, index }" slot="steelOffset">
                    <Input v-model="steelOffset" placeholder="输入天数" v-if="index == editIndex"/>
                    <div v-else>{{row.steelOffset}}</div>
                </template>
                <template slot-scope="{ row, index }" slot="handoverOffset">
                    <Input v-model="handoverOffset" placeholder="输入天数" v-if="index == editIndex"/>
                    <div v-else>{{row.handoverOffset}}</div>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" size="small" style="margin-right: 5px" @click="edit(row,index)" v-if="editIndex!=index">编辑</Button>
                    <div v-if="editIndex==index">
                        <Button type="primary" size="small" style="margin-right: 5px" @click="editIndex=-1;save(row.projectCode)">保存</Button>
                        <Button size="small" style="margin-right: 5px" @click="editIndex=-1">取消</Button>
                    </div>
                </template>
            </Table>
            <div class="page-box">
                 <Page :total="total" @on-change="pageChange" @on-page-size-change="pageSizeChange" show-sizer/>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                projectShortName:'',
                loading:false,
                editIndex: -1,
                total:0,
                pageNum:1,
                pageSize:10,
                columns1: [
                    {
                        title: '项目名称',
                        key: 'projectName',
                        // width: 250,
                        // fixed: 'left'
                    },
                    {
                        title: '项目编号',
                        key: 'projectShortName',
                        width: 180,
                        // fixed: 'left'
                    },
                    {
                        title: '偏移天数',
                        align: 'center',
                        children: [
                            {
                                title: '合同签订',
                                key: 'signingOffset',
                                slot: 'signingOffset',
                                width: 120,
                            },
                            {
                                title: '正式开工',
                                key: 'startOffset',
                                slot: 'startOffset',
                                width: 120,
                            },
                            {
                                title: '基础验收',
                                key: 'basicOffset',
                                slot: 'basicOffset',
                                width: 120,
                            },
                            {
                                title: '钢结构验收',
                                key: 'steelOffset',
                                slot: 'steelOffset',
                                width: 120,
                            },
                            {
                                title: '整体移交',
                                key: 'handoverOffset',
                                slot: 'handoverOffset',
                                width: 120,
                            }
                        ]
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        // fixed: 'right',
                        width: 150,
                    },
                ],
                data1: [],
                btnloading: false,
                //输入
                signingOffset:null,
                startOffset:null,
                basicOffset:null,
                steelOffset:null,
                handoverOffset:null,
            }
        },
        methods:{
            loadPage(){
                this.$http.get("/dashboard/pjConfigPage",{
                        pageNum:this.pageNum,
                        pageSize:this.pageSize,
                        projectShortName: this.projectShortName
                    }).then(rs=>{
                    this.data1 = rs.list;
                    this.total = rs.total;
                })
            },
            pageChange(e){
                console.log(e);
                this.pageNum = e;
                this.loadPage();
            },
            pageSizeChange(e){
                console.log(e);
                this.pageSize = e;
                this.loadPage();
            },
            search(){
                this.pageNum=1;
                this.loadPage();
            },
            edit(row,index){
                this.editIndex=index;
                this.signingOffset=row.signingOffset,
                this.startOffset=row.startOffset,
                this.basicOffset=row.basicOffset,
                this.steelOffset=row.steelOffset,
                this.handoverOffset=row.handoverOffset
            },
            save(projectCode){
                console.log(projectCode);
                this.$http.post('/dashboard/modifyConfig',{
                    projectCode: projectCode,
                    signingOffset:this.signingOffset,
                    startOffset:this.startOffset,
                    basicOffset:this.basicOffset,
                    steelOffset:this.steelOffset,
                    handoverOffset:this.handoverOffset
                }).then(rs=>{
                    if(rs){
                        this.$Message.success("保存成功！")
                        this.loadPage();
                    }
                })
            },
            writeBack(){
                this.btnloading = true;
                this.$http.get("/dashboard/writeBack").then(rs=>{
                    if(rs>=0){
                        this.$Message.success("回写成功！")
                    }
                    this.btnloading = false;
                })
            }
        },
        mounted() {
            this.loadPage();
        },
    }
</script>

<style scoped>
    .container{
		width: 100%;
        height: 100%;
		margin: 0 auto;
		padding: 24px 15px; 
		background: #fff;
	}
    .search-wrap{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
    .search-wrap .inp{
        width: 400px;
    }
    .table-wrap .page-box{
        margin-top: 20px;
        text-align: right;
    }

</style>